import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    HStack,
    Tooltip
} from '@chakra-ui/react';
import { CustomSpinner } from '../../../Spinner';
import { EditIcon } from '@chakra-ui/icons';
import { ApplyCellBg, FormatDateToMonthYear } from '../../../../Constants';
import {RowNameMappingOtherTable } from './OtherCanvasHelper';
import EditableDistributorCell from './EditableDistributorCell';
import { useCallback, useEffect } from 'react';

export default function OtherTable({ setButtonDisabled, setDataSaved, setChangesTracker, filter,dealer_filter,setTableData,tableData,loading }) {
    
    useEffect(()=>{},[])
    const handleChangesTracker = useCallback((newValue, columnID, rowId, forecast_version,dealer_no) => {
        setDataSaved(false)
        setChangesTracker((prevChanges) => {
            const updatedChanges = { ...prevChanges };
            const key = `${rowId},${columnID},${forecast_version},${dealer_no}`
            updatedChanges[key] = newValue;
            return updatedChanges;
        });
        setButtonDisabled(false)
    },[setButtonDisabled,setChangesTracker,setDataSaved])

    console.log("table")
    if (loading) return <CustomSpinner text="Loading Editor..." />
    return (
        <Table>
            <Thead style={{ position: 'sticky', top: 0, backgroundColor: 'white' }}>
                <Tr>
                    <Th>Dealer No</Th>
                    <Th textAlign>Category</Th>
                    <Th textAlign>Key Figure</Th>
                    {tableData.dates &&
                        tableData.dates.map((date, index) => <Th key={index}>{FormatDateToMonthYear(date)}</Th>)}
                </Tr>
            </Thead>
            <Tbody>
                { tableData?.dealer_no && tableData.dealer_no.filter(deal_no=>deal_no===dealer_filter).map((deal_no) => {
                    return ((tableData.dealer_wise_data[deal_no].data.length>0)?
                            Object.keys(tableData.dealer_wise_data[deal_no].data[0])
                            .filter(key => RowNameMappingOtherTable[key][0] === filter || filter === '')
                            .map((key, index) => {
                                return (
                                <Tr key={index}>
                                    <Td textAlign style={ApplyCellBg(key)}><Tooltip label={tableData.dealer_wise_data[deal_no].dealer_name} aria-label='Dealer Name'>{String(tableData.dealer_wise_data[deal_no].dealer_no)}</Tooltip></Td>
                                    <Td textAlign style={ApplyCellBg(key)}>{RowNameMappingOtherTable[key][0]}</Td>
                                    <Td textAlign style={ApplyCellBg(key)}>
                                        {
                                            RowNameMappingOtherTable[key][1].includes('Current Forecast') ?
                                                <HStack>
                                                    <span>Current Fcst</span><EditIcon mr={2} />
                                                </HStack>
                                                : RowNameMappingOtherTable[key][1]
                                        }
                                    </Td>

                                    {
                                    tableData.dates && tableData.dealer_wise_data[deal_no].data
                                    .map((item, index) => {
                                        if (
                                        key === 'distributor_current_forecast' ||
                                        key === 'consultant_current_forecast' ||
                                        key === 'revenue_current_forecast' ||
                                        key === 'show_current_forecast'
                                        ) {
                                        return (
                                            <EditableDistributorCell
                                            key={index}
                                            index={index}
                                            rowId={key}
                                            value={item[key]}
                                            forecast_version = {tableData.dealer_wise_data[deal_no].forecast_version}
                                            columnID={tableData.dealer_wise_data[deal_no].full_date[index]}
                                            onUpdate={handleChangesTracker}
                                            dealer_no={tableData.dealer_wise_data[deal_no].dealer_no}
                                            deal_no={deal_no}
                                            tableData={tableData}
                                            setTableData={setTableData}
                                            />
                                        );
                                        }
                                        return <Td key={index} textAlign style={ApplyCellBg(key)}>{item[key]}</Td>;
                                    })}
                                </Tr>
                                )}
                            )  :<Tr><Td></Td><Td></Td><Td>No Data</Td></Tr>
                    )
                    
                }
                )
                }
            </Tbody>
        </Table>
    );
}
