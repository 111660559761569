import React, { useState, useEffect, useRef } from 'react';

import { Td } from '@chakra-ui/react';
import { ApplyCellBg } from '../../../Constants';

function EditableRegionalCell({keyValue, value, columnID, onUpdate,saveEnabled ,dealer_type,regional_leader_id,forecast_version_date,FocusDealerForecast,current_revenue_forecast,pivotedData,setPivotedData}) {
    const [cellValue, setCellValue] = useState(!value&&value!==0?'':parseFloat(value).toFixed(0));
    const cellRef = useRef(null);
    const cellRef2 = useRef(null);
    const [valueEdited,setValueEdited]=useState(false)
    const [width, setWidth] = useState(value?parseFloat(value).toFixed(0).toString().length+2:0)

  useEffect(() =>{ 
    setCellValue(!value&&value!==0?'':parseFloat(value).toFixed(0))
    setWidth(value?parseFloat(value).toFixed(0).toString().length+2:0)
    const color = parseFloat(value).toFixed(0)===parseFloat(current_revenue_forecast).toFixed(0)?"#f5deb3":"#90ee90"
    cellRef.current.style.backgroundColor= color
    cellRef2.current.style.backgroundColor=color
    setValueEdited(false) 
  }, [value,current_revenue_forecast]);

  useEffect(()=>{
    const timeOutId = setTimeout(() =>{
      if(valueEdited){
      console.log("useEffect",cellValue)
      const updateValue = cellValue!==''?FocusDealerForecast?(parseFloat(cellValue)-FocusDealerForecast):parseFloat(cellValue):null
      onUpdate(updateValue, columnID, dealer_type,regional_leader_id,forecast_version_date);
      // var dataTemp=pivotedData
      // dataTemp[columnID]["Final Revenue Forecast"]=cellValue
      // setPivotedData(dataTemp)
      }
    }, 500);
    return () => clearTimeout(timeOutId);
  },[cellValue, columnID,onUpdate,valueEdited,forecast_version_date,regional_leader_id,dealer_type,FocusDealerForecast])
  /**
   * The Change in the editable cell is monitored
   */
  const handleInput = (e) => {
    const newValue = e.target.value;
    const regex=/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/
    if(regex.test(newValue)||newValue===""){
    setWidth(newValue.length+2)
    setCellValue(newValue)
    setValueEdited(true)
    const color = parseFloat(newValue).toFixed(0)===parseFloat(current_revenue_forecast).toFixed(0)?"#f5deb3":"#90ee90"
    cellRef.current.style.backgroundColor= color
    cellRef2.current.style.backgroundColor=color 
    }
    
  }

  return (
    <Td textAlign ref={cellRef} className="paper-gradient" style={{...ApplyCellBg('qty_override_forecast'),overflow:"auto",whiteSpace:"nowrap"}} key={`${keyValue}-${columnID}-editable_cell`}>
        $ <input
          key={`${keyValue}-editable_cell-${columnID}`}
          type="text"
          min={0}
          ref={cellRef2}
          onInput={handleInput}
          value={cellValue}
          style={{
            backgroundColor: '#f5deb3', // Add a very light background color
            padding: '7px', // Add some padding for a better look
            border: '1px solid gray',
            borderRadius: '5px',
            width:width +'ch',
            minWidth:'40px',
            textAlign: 'center',
            fontWeight:'bold'
          }}
          disabled={!saveEnabled}
        />
    </Td>
  ); 
}


export default EditableRegionalCell;