import { GetUniqueValues } from "../SKULevel/SKUCanvasHelper";

export const RowNameMappingOtherTable = {
    distributor_year_ago_hist: ['Distributor', 'YAG Distributor'],
    distributor_last_cycle_forecast: ['Distributor', 'Prev Fcst'],
    distributor_current_forecast: ['Distributor', 'Current Forecast'],
    consultant_year_ago_hist: ['Consultant', 'YAG Consultant'],
    consultant_last_cycle_forecast: ['Consultant', 'Prev Fcst'],
    consultant_current_forecast: ['Consultant', 'Current Forecast'],
    revenue_year_ago_history: ['Purchases', 'YAG Purchases'],
    revenue_last_cycle_forecast: ['Purchases', 'Prev Fcst'],
    revenue_stat_baseline: ['Purchases', 'Stat Fcst'],
    revenue_current_forecast: ['Purchases', 'Current Forecast'],
    Cooked_Show_Year_ago_Hist: ['Shows', 'YAG Cooked Shows'],
    show_last_cycle_forecast: ['Shows', 'Prev Fcst'],
    show_current_forecast: ['Shows', 'Current Forecast']
};

export function ParseOtherTableData(jsonData, setTableData,setDealer_no_all,setDealer_filter) {
    const dealer_no= GetUniqueValues(jsonData, 'dealer_no_all')
    const dates= GetUniqueValues(jsonData,'Date')
    const dealer_wise_data = {}
    dealer_no.forEach((deal_no,index)=>{
        const forecast_version=jsonData[0].forecast_version
        const data = [];
        const full_date = [];
        var dealer_no_int;
        var dealer_name;
        if (jsonData['Error']) return;
        Object.values(jsonData.filter((data)=>data["dealer_no_all"]===deal_no)).forEach((item) => {
        if(dealer_no_int===undefined){
            dealer_no_int=item.dealer_no
        }
        if(dealer_name===undefined){
            dealer_name=item.dealer_name
        }
        full_date.push(item.Date);
            const newItem = {
                distributor_year_ago_hist: item.distributor_year_ago_hist,
                distributor_last_cycle_forecast: item.distributor_last_cycle_forecast,
                distributor_current_forecast: item.distributor_current_forecast,
                consultant_year_ago_hist: item.consultant_year_ago_hist,
                consultant_last_cycle_forecast: item.consultant_last_cycle_forecast,
                consultant_current_forecast: item.consultant_current_forecast,
                Cooked_Show_Year_ago_Hist: item.Cooked_Show_Year_ago_Hist,
                show_last_cycle_forecast: item.show_last_cycle_forecast,
                show_current_forecast: item.show_current_forecast,
                revenue_year_ago_history: item.revenue_year_ago_history|item.revenue_year_ago_history===0 ? `$${Math.ceil(parseFloat(item.revenue_year_ago_history)).toLocaleString('en-us')}` : "",
                revenue_last_cycle_forecast: item.revenue_last_cycle_forecast|item.revenue_last_cycle_forecast===0 ? `$${Math.ceil(parseFloat(item.revenue_last_cycle_forecast)).toLocaleString('en-us')}` : "",
                revenue_stat_baseline: item.revenue_stat_baseline | item.revenue_stat_baseline===0? `$${Math.ceil(parseFloat(item.revenue_stat_baseline)).toLocaleString('en-us')}` : "",
                revenue_current_forecast: item.revenue_current_forecast
            };
            data.push(newItem);
        });
    dealer_wise_data[deal_no]={data,full_date,forecast_version,dealer_no:dealer_no_int,dealer_name:dealer_name}
})
    setTableData({dealer_no,dealer_wise_data,dates});
    setDealer_no_all(dealer_no)
    setDealer_filter(dealer_no[0])
}

export function ParseOtherTableDataReport(jsonData, setTableData,setDealer_no_all,setDealer_filter) {

    const copyPreviousForecast = (current, previous) => {
        return current !== null && current !== undefined ? current : previous;
    };
    const dealer_no= GetUniqueValues(jsonData, 'dealer_no_all')
    const dates= GetUniqueValues(jsonData,'Date')
    const dealer_wise_data = {};
    dealer_no.forEach((deal_no,index)=>{
    var dealer_no_int;
    var dealer_name;
    const data = [];
    const full_date = [];
    const totals = {};
    const nonBlanks = {};
    const AverageKeys = ['distributor_current_forecast','distributor_last_cycle_forecast','distributor_year_ago_hist',
                        'consultant_year_ago_hist','consultant_last_cycle_forecast','consultant_current_forecast']
    if (jsonData['Error']) return;
    Object.values(jsonData.filter((data)=>data["dealer_no_all"]===deal_no)).forEach((item) => {
        if(dealer_no_int===undefined){
            dealer_no_int=item.dealer_no
        }
        if(dealer_name===undefined){
            dealer_name=item.dealer_name
        }
    full_date.push(item.Date);
    const newItem = {
        distributor_year_ago_hist: item.distributor_year_ago_hist,
        distributor_last_cycle_forecast: item.distributor_last_cycle_forecast,
        distributor_current_forecast: copyPreviousForecast(
            item.distributor_current_forecast,
            item.distributor_last_cycle_forecast
        ),
        consultant_year_ago_hist: item.consultant_year_ago_hist,
        consultant_last_cycle_forecast: item.consultant_last_cycle_forecast,
        consultant_current_forecast: copyPreviousForecast(
          item.consultant_current_forecast,
          item.consultant_last_cycle_forecast
        ),
        Cooked_Show_Year_ago_Hist: item.Cooked_Show_Year_ago_Hist,
        show_last_cycle_forecast: item.show_last_cycle_forecast,
        show_current_forecast: copyPreviousForecast(
          item.show_current_forecast,
          item.show_last_cycle_forecast
        ),
        revenue_year_ago_history: item.revenue_year_ago_history,
        revenue_last_cycle_forecast: item.revenue_last_cycle_forecast,
        revenue_stat_baseline: item.revenue_stat_baseline,
        revenue_current_forecast: copyPreviousForecast(
            item.revenue_current_forecast,
            copyPreviousForecast(item.revenue_last_cycle_forecast,item.revenue_stat_baseline)
        )
      };
        data.push(newItem);
        Object.keys(newItem).forEach((key) => {
            if (!isNaN(parseFloat(newItem[key]))) {
                totals[key] = (totals[key]||0) + parseFloat(newItem[key]);
                nonBlanks[key] = (nonBlanks[key] || 0 )+1
            }
        });
    });
    AverageKeys.forEach((key)=>totals[key]=Math.round(totals[key]/nonBlanks[key]))
    dealer_wise_data[deal_no]={data,full_date,totals,dealer_no:dealer_no_int,dealer_name};
})
    setTableData({ dates, dealer_wise_data, dealer_no });
    setDealer_no_all(dealer_no)
    setDealer_filter(dealer_no[0])
}

// export const UpdateOtherTableData = (newValue, columnID, rowId, userProfile) => {
    
//     // console.log(newValue,columnID,rowId,userProfile)
//     const data = { 
//         "date": columnID, 
//         "newValue": newValue === "" ? "NULL" : parseInt(newValue),
//         "dealer_no": userProfile, 
//         "category": rowId
//     };
//     // Only half data coming here

    

//     fetch(`${API_URL}/updateDistForecast`, {
//         method: 'PUT',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(data)
//     })
//     .then(response => response.json())
//     .then(data => console.log(data))
//     .catch(error => console.log(error));
// };