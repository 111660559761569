import { Flex, Container ,Stack,Tabs, TabList, Tab, TabPanel, TabPanels} from "@chakra-ui/react";
import RegionalLeaderTable from './RegionalLeaderTable';
import OtherCanvas from "./OtherLevel/OtherCanvas";
import Notification from "./../Notifications/NotificationTable";
import  SkuTable  from "./SKULevel/SkuTable";



export default function DashboardContent({setDataSavedSku,setDataSavedOther,activeRegion,account_id}) {

    return (
        <Flex flex="1" flexDirection="column">
            <Container maxW="container.xxl" py={10}>
                <Stack spacing={8}>
                {Object.keys(activeRegion).length>0 && <RegionalLeaderTable  name={activeRegion.regional_leader_name} region={activeRegion.region} />}
                {Object.keys(activeRegion).length>0 &&  <Tabs variant="enclosed" bg="white" boxShadow="md">
                        <TabList>
                            <Tab>SKU Forecast</Tab>
                            <Tab>Other Forecast</Tab>
                            <Tab>Notifications</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                {Object.keys(activeRegion).length>0 && <SkuTable userProfile={activeRegion["ID"]} setDataSaved={setDataSavedSku} account_id={account_id}/>}
                            </TabPanel>
                            <TabPanel>
                                {Object.keys(activeRegion).length>0 &&  <OtherCanvas userProfile={activeRegion["ID"]} setDataSaved={setDataSavedOther} account_id={account_id}/>}
                            </TabPanel>
                            <TabPanel>
                                {Object.keys(activeRegion).length>0 && <Notification userProfile={activeRegion} />}
                            </TabPanel>
                        </TabPanels>
                    </Tabs>}
                </Stack>
            </Container>
        </Flex>
    );
}