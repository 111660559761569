import React, { useEffect } from 'react';
import {
    Table, Tbody, Thead, TableContainer,
    Td, Th, Tr, HStack, Button, Box
} from '@chakra-ui/react';
import { IoMdPrint } from 'react-icons/io';
import useFetch from '../../../hooks/useFetchWithMsal';

export default function Notification({ userProfile }) {
    const [{ data: notifications }, fetchData] = useFetch([]);
    useEffect(() => {
        fetchData("/notification", { method: "POST", body: {id:userProfile["ID"]}})
    }, [fetchData, userProfile]);

    return (
        <Box>
            <HStack spacing={4}>
                <Button leftIcon={<IoMdPrint />} onClick={() => window.print()} colorScheme='blue'>
                    Print
                </Button>
            </HStack>
            <Box id="printableArea">
                <TableContainer >
                    <Table>
                        <Thead>
                            <Tr>
                                <Th textAlign>Division</Th>
                                <Th textAlign>Region</Th>
                                <Th textAlign>Site</Th>
                                <Th textAlign>Notification</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {notifications?.map((notification, index) => (
                                <Tr key={index}>
                                    <Td textAlign>{notification["Division"]}</Td>
                                    <Td textAlign>{notification["Region"]}</Td>
                                    <Td textAlign>{notification["Site"]}</Td>
                                    <Td textAlign>{notification["Notification"]}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );

}