import { Menu, MenuButton, MenuList, MenuItem, Avatar, Button, Text,Link } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom";
import { useMsal, useAccount } from '@azure/msal-react';
import { Icon } from "@chakra-ui/react";
import { FiLogOut } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
import { ExternalLinkIcon } from "@chakra-ui/icons";


export default function UserMenu({dataSave}) {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const navigate = useNavigate();
  /**
   * Callback to handle logout
   */
  const handleLogout = () => {
      if(!dataSave && !window.confirm("There are unsaved changes.If you navigate all the changes would be lost?")){
          return
    }else{
      sessionStorage.clear()
      instance.logout();
      navigate("/");
      }
  };

  if(!account) return;
  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<Avatar size="sm" name={account.name} />}
        variant="ghost"
        colorScheme="whiteAlpha"
        px={4}  // Add horizontal padding
        textAlign="right"  // Align the content to the right
      >
        {account.name}
      </MenuButton>
      <MenuList
        minWidth="180px"  // Adjust the minimum width of the menu list
        textAlign="right"  // Align the content inside the menu list to the right
      >
        <Text color="black" px={4} py={2}>
          <Icon as={HiOutlineMail} mr={2} />
          {account.username}
        </Text>
        <MenuItem color="black" onClick={handleLogout} px={4} py={2}>
          <Icon as={FiLogOut} mr={2} /> 
          Logout
        </MenuItem>
        <MenuItem color="black" href={require("../../documentation/Forecast App Manual.pdf")} target="blank" px={4} py={2}>
            <Link href={require("../../documentation/Forecast App Manual.pdf")} target="blank">
            <Icon as={ExternalLinkIcon} mr={2} /> 
              Documentation    
            </Link>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}