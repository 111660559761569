import React from 'react';
import { HStack, Td, Tr } from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import EditableCell from './EditableCell';
import { ApplyCellBg } from '../../../../Constants';
import { YearEndTotal } from './SKUCanvasHelper';
import { OVERRIDE_FORECAST, LAST_CYCLE_FORECAST, SKUTypelist } from './SKUCanvasHelper';
import { Tooltip } from '@chakra-ui/react';


export const DataRow = ({ rowName, index, uniqueDates, data,setData,wholeData, handleChangesTracker }) => {
  const type = SKUTypelist[index];

  const uid = function(){
    return Date.now().toString(36) + Math.random().toString(36);
  }



  // const rowDataSums = uniqueDates.map((date) => {
  //   const items = data.filter((item) => item.date === date);
  //   return items.map((item) => parseFloat(item[type])).reduce((acc, curr) => acc + (isNaN(curr) ? 0 : curr), 0);
  // });
  // const rowDataSumsTotal = YearEndTotal(rowDataSums);
  
  return (
    <Tr key={uid}>
      <Td textAlign style={ApplyCellBg(type)}><Tooltip label={data[0]['dealer_name']} aria-label='Dealer Name'>{String(data[0]['dealer_no'])}</Tooltip></Td>
      <Td textAlign style={ApplyCellBg(type)}>{data[0]['SKU_Description']}</Td>
      <Td textAlign style={ApplyCellBg(type)}>{data[0]['SKU_code']}</Td>
      <Td textAlign style={ApplyCellBg(type)}>{rowName.includes('Current Forecast') ? <HStack><span>Current Fcst </span><EditIcon mr={2} /></HStack> : rowName }</Td>
      {uniqueDates.map((date, index) => {
        const item = data.find((item) => item.date === date);
        // if(type==='qty_override_forecast' ) {console.log(item[type])}
        return type === 'qty_override_forecast' ? (
          <EditableCell
            key={`${index}-${data[0]['SKU_code']}-${date}-${data[0]['forecast_version_date']}`}
            value={item[type]}
            dealer_no={item["dealer_no"]}
            columnID={date}
            onUpdate={handleChangesTracker}
            skuCode={item['SKU_code']}
            forecast_version_date={item["forecast_version_date"]}
            wholeData={wholeData}
            setData={setData}
          />
        ) : (
          <Td key={`${index}-${data[0]['SKU_code']}-${date}`} style={ApplyCellBg(type)} textAlign>{item[type]}</Td>
        );
      })}
    </Tr>
  );
};

export function DataRowReport({ rowName, index, uniqueDates, data }) {
  const type = SKUTypelist[index];

  const uid = function(){
    return Date.now().toString(36) + Math.random().toString(36);
  }

  /**
   * Get the sums of all values in a given row
   */
  const rowDataSums = uniqueDates.map((date) => {
    const items = data.filter((item) => item.date === date);
    return items.map((item) => type === OVERRIDE_FORECAST&&!item[type]&&item[type]!==0?
    !item[LAST_CYCLE_FORECAST]&&item[LAST_CYCLE_FORECAST]!==0?parseFloat(item['qty_stat_baseline']):parseFloat(item[LAST_CYCLE_FORECAST]):parseFloat(item[type])).reduce((acc, curr) => acc + (isNaN(curr)?0:curr), 0);
  });
  const rowDataSumsTotal = YearEndTotal(rowDataSums); // Rolling 12 month total
  return (
    // current forecast not available then last cycle foreast, If that not available take stat forecast.
    <Tr key={uid}>
      <Td textAlign style={ApplyCellBg(type)}><Tooltip label={data[0]['dealer_name']} aria-label='Dealer Name'>{String(data[0]['dealer_no'])}</Tooltip></Td>
      <Td style={ApplyCellBg(type)} textAlign>{data[0]['SKU_Description']}</Td>
      <Td style={ApplyCellBg(type)} textAlign>{data[0]['SKU_code']}</Td>
      <Td style={ApplyCellBg(type)} textAlign>{rowName}</Td>
      {uniqueDates.map((date) => {
        const item = data.find((item) => item.date === date);
        return type === OVERRIDE_FORECAST && !item[type] && item[type]!==0? 
            !item[LAST_CYCLE_FORECAST] && item[LAST_CYCLE_FORECAST]!==0? (
           <Td key={`${type}-${date}`} style={ApplyCellBg(type)} textAlign>{item['qty_stat_baseline']}</Td>
        ):(<Td key={`${type}-${date}`} style={ApplyCellBg(type)} textAlign>{item[LAST_CYCLE_FORECAST]}</Td>) : (
          <Td key={`${type}-${date}`} style={ApplyCellBg(type)} textAlign>{item[type]}</Td>
        );
      })}
      <Td textAlign style={{...ApplyCellBg(type),fontWeight:"bold"}}>{rowDataSumsTotal}</Td>
    </Tr>
  );
}
