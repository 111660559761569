import React, { useState, useEffect } from 'react';
import { Box, HStack, Select, Flex, FormLabel, Text,Spacer } from '@chakra-ui/react';
import OtherTable from './OtherTable';
// import { UpdateOtherTableData } from './OtherCanvasHelper';
import SaveButton from '../../../Save';
import { RowNameMappingOtherTable } from './OtherCanvasHelper';
import useFetch from '../../../../hooks/useFetchWithMsal';
import { ParseOtherTableData } from './OtherCanvasHelper';

export default function OtherCanvas({ userProfile,setDataSaved,account_id }) {
    const [{ loading }, fetchData] = useFetch([]);
    const [changesTracker, setChangesTracker] = useState({});
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [filter, setFilter] = useState('');
    const [dealer_no_all,setDealer_no_all]=useState([])
    const [dealer_filter,setDealer_filter]=useState(dealer_no_all[0])
    const [, updateData] = useFetch()
    const [tableData, setTableData] = useState({});
    const categories = [...new Set(Object.values(RowNameMappingOtherTable).map(arr => arr[0]))];



    useEffect(() => {
        fetchData(`/regionalDistributor?userID=${userProfile}`, { method: "GET" }).then((jsonData) => {
            ParseOtherTableData(jsonData, setTableData,setDealer_no_all,setDealer_filter)
            setDataSaved(true)
            setIsButtonDisabled(true);
            setChangesTracker({})
        })
    }, [userProfile, fetchData,setTableData,setDataSaved]);

    const uploadData = () => {
        if (!userProfile) return;
        let data = []
        Object.keys(changesTracker).forEach((key) => {
            const [rowId, columnID,forecast_version,dealer_no] = key.split(",");
            console.log(key)
            data.push({ 
                "date": columnID, 
                "newValue": changesTracker[key] === "" ? null : parseFloat(changesTracker[key]).toFixed(0),
                "dealer_no": dealer_no, 
                "category": rowId,
                "forecast_version":forecast_version,
                "update_by":account_id,
                "update_date":new Date().toISOString()
            });
        });
        console.log("changes tracker",data)
        updateData("/updateDistForecast", {method: "PUT", body: data}).then((data) => {
            setIsButtonDisabled(true)
            setChangesTracker({});
            setDataSaved(true)
        }).catch((error) => {
            console.log(error)
        });
    };


    return (
        <Box height="70vh">
            <HStack spacing={4}>
            <Flex alignItems="center">
            <FormLabel htmlFor="category" 
                mb="0" mr="2"
                color="gray.600" fontWeight="bold"
                fontSize="sm">
                Category
            </FormLabel>
            <Select id="category" value={filter} onChange={e => setFilter(e.target.value)}>
                <option value="">All</option>
                {categories.map((category, index) => (
                    <option key={index} value={category}>{category}</option>
                ))}
            </Select>
            </Flex>
            <Flex alignItems="center">
            <FormLabel htmlFor="dealer_no" 
                mb="0" mr="2"
                color="gray.600" fontWeight="bold"
                fontSize="sm">
                Dealer No
            </FormLabel>
            <Select id="dealer_no" value={dealer_filter} onChange={e => setDealer_filter(e.target.value)}>
                {dealer_no_all && dealer_no_all.map((dealer_no, index) => (
                    <option key={index} value={dealer_no}>{dealer_no}</option>
                ))}
            </Select>
            </Flex>
            <SaveButton uploadData={uploadData} isButtonDisabled={isButtonDisabled} />
            <Spacer />
        <Text
        fontSize="sm" // Adjust the font size to your preference
        fontWeight="bold" // Apply bold font weight to the caption
        color="gray.500" // Use a gray color for the caption
        marginBottom="2" // Add some bottom margin for spacing
        >*Shows represent cooked shows</Text>
            
            </HStack>
            <Box height="65vh" overflow="auto">
                {userProfile &&Object.keys(tableData).length>0 && <OtherTable setButtonDisabled={setIsButtonDisabled} setDataSaved={setDataSaved} setChangesTracker={setChangesTracker} filter={filter} dealer_filter={dealer_filter} setTableData={setTableData} tableData={tableData} loading={loading}/>}
            </Box>
        </Box>
    );

};