import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Box, HStack, Table, Tbody, Th, Thead, Tr, Td, Spacer, Text } from '@chakra-ui/react';
import { ProductLine, Category,Dealer } from './Filter';
import { FormatDateToMonthYear } from '../../../../Constants';
import { DataRow } from './DataRows';
import { SKURowNames, GetUniqueValues } from './SKUCanvasHelper';
import { CustomSpinner } from '../../../Spinner';
import SaveButton from '../../../Save';
import useFetch from '../../../../hooks/useFetchWithMsal';

export default function SkuTable ({ userProfile,setDataSaved,account_id }) {
  const [changesTracker, setChangesTracker] = useState({});
  const [data,setData]=useState([])
  const [{ loading, error }, fetchData] = useFetch([]);
  const uniqueDates = GetUniqueValues(data, 'date'); // Get all the rolling 12 month date
  const uniqueProductLine = GetUniqueValues(data, 'Product_Line'); // Get all unique product line info
  const uniqueProductCategory = useMemo(() => GetUniqueValues(data, 'Product_Category'), [data]);
  const uniqueDealers=GetUniqueValues(data,'dealer_no_all')
  const tableHeaders = uniqueDates.map((date) => <Th textAlign key={date}>{FormatDateToMonthYear(date)}</Th>);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [selectedProductLine, setSelectedProductLine] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedDealer,setSelectedDealer]=useState(uniqueDealers[0])
  const [filteredUniqueProductCategory, setFilteredUniqueProductCategory] = useState(uniqueProductCategory);
  const [, updateData] = useFetch();
  

  useEffect(() => {
    if (!userProfile) return;
    fetchData(`/regional_products?userID=${userProfile}`, {method: "GET"}).then((data1) => {
      const uniqueProductCategory = GetUniqueValues(data1, 'Product_Category');
      const uniqueDealers=GetUniqueValues(data1,'dealer_no_all')
      setData(data1)
      setFilteredUniqueProductCategory(uniqueProductCategory);
      setSelectedDealer(uniqueDealers[0])
      setIsButtonDisabled(true);
      setDataSaved(true)
      setChangesTracker({})
    })
  }, [userProfile ,setFilteredUniqueProductCategory, fetchData,setDataSaved]);

  /**
   * 
   * @param {*} newValue updated value
   * @param {*} columnID date to update the value
   * @param {*} rowId sku code for updated value
   * Handle all the changes to the editable cell in the table
   */

  const handleChangesTracker =useCallback((newValue, columnID,dealer_no,rowId,forecast_version_date) => {
    setIsButtonDisabled(false);
    setDataSaved(false)
    setChangesTracker((prevChanges) => {
      const updatedChanges = { ...prevChanges };
      const key = `${rowId},${dealer_no},${columnID},${forecast_version_date}`;
      updatedChanges[key] = newValue;
      return updatedChanges;
    });
    
  },[setDataSaved,setIsButtonDisabled,setChangesTracker]);

  /**
   * Upload data once after enter key is pressed. Once the data is successfullu
   * updated the changes tracker is emptied and a success Toast is shown.
   */
  const uploadData = () => {
    let data = []
    Object.entries(changesTracker).forEach(([key, newValue]) => {
      const [rowId,dealer_no,columnID,forecast_version_date] = key.split(",");

      data.push({ 
        date: columnID, 
        newValue: newValue === "" ? null : parseFloat(newValue).toFixed(0), 
        sku_code: rowId, 
        dealer_no: dealer_no,
        forecast_version_date:forecast_version_date,
        update_by:account_id,
        update_date:new Date().toISOString()
      });
    })
    console.log(data)
    updateData("/update-data", {method: "PUT", body: data}).then((data) => {
      setChangesTracker({});
      setIsButtonDisabled(true);
      setDataSaved(true)
    }).catch((error) => {
      console.log(error)
  });

  };

  /**
   * Filter all the data in the selected product line and selected category
   */
  const filteredData = data?.filter(
    (item) =>
      (!selectedProductLine || item['Product_Line'] === selectedProductLine) &&
      (!selectedCategory || item['Product_Category'] === selectedCategory) &&
      (item['dealer_no_all']===selectedDealer)
  );

  /**
   * Groups all the response data based on sku code to display in the 
   * table.
   */
  const groupedData = filteredData.reduce((grouped, item) => {
    const dealer_skuCode = item["dealer_no"]+"-"+item['SKU_code'];
    grouped[dealer_skuCode] = grouped[dealer_skuCode] || [];
    grouped[dealer_skuCode].push(item);
    return grouped;
  }, {});

  /**
   * 
   * @param {dict} data to be converted into table rows
   * @param {int} itemIndex index of each item
   * @returns {DataRow} each row in the table
   */
  const renderDataRows = (data1, itemIndex) => {
    return SKURowNames.map((rowName, index) => (
      <DataRow
        key={`${index}-${data1[0]['SKU_code']}-${data1[0]['Product_Line']}-${data1[0]['dealer_no']}`}
        rowName={rowName}
        index={index}
        uniqueDates={uniqueDates}
        data={data1}
        setData={setData}
        wholeData={data}
        handleChangesTracker={handleChangesTracker}
      />
    ));
  };

  /**
   * DataRows rendered and grouped
   */
  const dataRows = Object.values(groupedData).map(renderDataRows);

  const handleProductLineChange = (selectedOption) => {
    setSelectedProductLine(selectedOption);
    setSelectedCategory('');
    const filteredCategories = data
    .filter(item => selectedOption ? item['Product_Line'] === selectedOption : true)
    .map(item => item['Product_Category']);
    setFilteredUniqueProductCategory([...new Set(filteredCategories)]);
  }
  const handleCategoryChange = (selectedOption) => setSelectedCategory(selectedOption);
  const handleDealerChange = (selectedOption)=>setSelectedDealer(selectedOption)
  /**
   * Show Custom Spinner while data is being fetched.
   */
  if (loading) return <CustomSpinner text="Loading Editor..." />

  return (
    <Box height="70vh">
      <HStack spacing={4}>
        <ProductLine value={selectedProductLine} options={uniqueProductLine} onChange={handleProductLineChange}/>
        <Category value={selectedCategory} options={filteredUniqueProductCategory} onChange={handleCategoryChange}/>
        <Dealer value={selectedDealer} options={uniqueDealers} onChange={handleDealerChange}/>
        <SaveButton uploadData={uploadData} isButtonDisabled={isButtonDisabled} />
        <Spacer />
        <Text
        fontSize="sm" // Adjust the font size to your preference
        fontWeight="bold" // Apply bold font weight to the caption
        color="gray.500" // Use a gray color for the caption
        marginBottom="2" // Add some bottom margin for spacing
        >* Data in units</Text>
      </HStack>
      <Box height="65vh" overflow="auto" textAlign="center">
        <Table textAlign="center">
          <Thead style={{ position: 'sticky', top: 0, backgroundColor: 'white' }} textAlign="center">
            <Tr>
              <Th textAlign>Dealer Number</Th>
              <Th textAlign>SKU Description</Th>
              <Th textAlign>SKU Code</Th>
              <Th textAlign>Key Figure</Th>
              {tableHeaders}
              {/* <Th style={{fontWeight:"bold", color:"black"}}>Total</Th> */}
            </Tr>
          </Thead>
          <Tbody>
            { error &&                 
                <Tr>
                  <Td colSpan={16} textAlign>
                    <Text> Error Occured: {error.message} </Text>
                  </Td>
                </Tr>}
            {
              dataRows.length > 0 ? 
                (dataRows):
                (
                <Tr>
                  <Td colSpan={16} textAlign>
                    <Text>No Data Available. Please remove filters.</Text>
                  </Td>
                </Tr>
                )
            }</Tbody>
        </Table>
      </Box>
    </Box>
  );
};
