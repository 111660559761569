import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import "./App.css";
// import Dashboard from "./components/Dashboard/Dashboard";
import RegionalView from './components/Dashboard/RegionalLevel/RegionalView'
import RegionalDashboard  from './components/Dashboard/RegionalLevel/Dashboard';
import RegionalDashboardReport  from './components/Dashboard/RegionalLevel/DashboardReport';
// import { Auth } from "./components/Login/Auth";
import Login from "./components/Login/Login";
// import DashboardReport from "./components/Dashboard/DashboardReport";
import { Navigate } from 'react-router-dom';

function App() {
	return (
		<ChakraProvider>
			<Router>
				<div className="App">
					<Routes>
						<Route index path="/" element={<Login />} />
						{/* <Route path="/dashboard" element={<Dashboard />} /> */}
						{/* <Route path="/auth" element={<Auth />} /> */}
						{/* <Route path="/forecastReport" element={<DashboardReport />} /> */}
						{/* <Route index path="/dashboard" element={<TokenTest />} /> */}
						<Route path="/regionalView" element={<RegionalView />} />
						<Route path="/regionalDashboard" element={<RegionalDashboard />} />
						<Route path="/regionalDashboardReport" element={<RegionalDashboardReport />} />
						<Route path="/*" element={<Navigate to="/" replace={true} />} />
					</Routes>
				</div>
			</Router>
		</ChakraProvider>
	);
}

// function TokenTest() {
//     const [{ data, loading, error }, fetchData] = useFetch();

//     useEffect(() => {
//         fetchData("/authorizedPing", {method: "GET"});
//     }, [])
    
//     return <div>{data.message ? data.message : "loading1..."}</div>
// }

export default App;
