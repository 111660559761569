import React, { useState, useEffect, useRef } from 'react';

import { Td } from '@chakra-ui/react';
import { ApplyCellBg } from '../../../../Constants';

function EditableCell({keyValue, value,dealer_no,columnID, onUpdate, skuCode,forecast_version_date,wholeData,setData}) {
    const [cellValue, setCellValue] = useState(!value&&value!==0?'':parseFloat(value).toFixed(0));
    const cellRef = useRef(null);
    const [width, setWidth] = useState(value?value.toString().length+2:0)
    const [valueEdited,setValueEdited]=useState(false)

  useEffect(() =>{ 
    setCellValue(!value&&value!==0?'':parseFloat(value).toFixed(0))
    setWidth(value?value.toString().length+2:0);
    setValueEdited(false)
}, [value]);

  useEffect(()=>{
    const timeOutId = setTimeout(() =>{
      if(valueEdited){
      onUpdate(cellValue, columnID,dealer_no,skuCode,forecast_version_date);
      var dataTemp=wholeData.map((data2)=>{
        if(data2["dealer_no"]===dealer_no && data2["SKU_code"]===skuCode && data2["date"]===columnID ){
            data2["qty_override_forecast"]=cellValue
        }
        return data2
      })
      setData(dataTemp)
      }
    }, 500);
    return () => clearTimeout(timeOutId);
  },[cellValue, columnID,forecast_version_date,dealer_no,onUpdate,valueEdited,setData,skuCode,wholeData])
  /**
   * The Change in the editable cell is monitored
   */
  const handleInput = (e) => {
    const newValue = e.target.value;
    const regex=/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/
    if(regex.test(newValue)||newValue===""){
    setWidth(newValue.length+2)
    setCellValue(newValue);
    setValueEdited(true)
    }

  }

  return (
    <Td textAlign className="paper-gradient" style={ApplyCellBg('qty_override_forecast')} key={keyValue}>
        <input
          type="text"
          ref={cellRef}
          onInput={handleInput}
          value={cellValue}
          style={{
            backgroundColor: '#f5deb3', // Add a very light background color
            padding: '7px', // Add some padding for a better look
            border: '1px solid gray',
            borderRadius: '5px',
            width:width +'ch',
            minWidth:'40px',
            textAlign: 'center'
          }}
        />
    </Td>
  ); 
}


export default EditableCell;