import { FormatDateToMonthYear } from '../../../../Constants';
import { Th } from '@chakra-ui/react';
// import { useNavigate } from 'react-router-dom';

export const SKURowNames = [
  'YAG Purchases',
  'Prev Fcst',
  'Stat Fcst',
  'Current Forecast',
];

export const LAST_CYCLE_FORECAST = 'qty_last_cycle_forecast';
export const OVERRIDE_FORECAST = 'qty_override_forecast';

export const SKUTypelist = ['qty_year_ago_history', 
  LAST_CYCLE_FORECAST, 
  'qty_stat_baseline', 
  OVERRIDE_FORECAST, 
  'total'
];


// export const NavigateToDifferentPage = (link,isDataSaved) => {
//   const navigate = useNavigate()
//   // Check if there are unsaved changes and prompt the user
//   if (!isDataSaved && !window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
//     return;
//   }
//   else{
//       // 
//   }
//   // Navigate to the different page
//   // Implement your navigation logic here, such as using react-router or changing window.location
//   // Example: history.push('/different-page');
// };

// THIS FUNCTION SEEMS TO BE UNUSED PLEASE DELETE IF NOT NECESSARY
/*
fetch product data for a given userprofile
*/
// export async function fetchData(userProfile, signal) {
//   try {
//     const url = new URL(`${API_URL}/products?userID:${userProfile}`)
//     const response = await fetch(url, { signal }); // Pass the signal here
//     const responseData = await response.json();

//     // make sure responseData is an array before calling JSON.parse
//     return Array.isArray(responseData) ? JSON.parse(responseData) : [];
//   } catch (error) {
//     console.error(error);
//     return []; // return an empty array in case of error
//   }
// }

/*
Generate table headers from the received response
*/
export const GetTableHeaders = (dates) => {
    return dates.map((date) => <Th textAlign key={date}>{FormatDateToMonthYear(date)}</Th>);
};

/*
Handle logic for updating SKU data
*/
// export const skuUpdatePayload = (newValue, columnID, skuCode, userProfile) => {
//   // const [{ data, loading, error }, fetchData] = useFetch();
  
//   const payload = { 
//     date: columnID, 
//     newValue: newValue === "" ? "NULL" : parseInt(newValue), 
//     sku_code: skuCode, 
//     dealer_no: userProfile 
//   };
//   return payload;

//   // fetch(`${API_URL}/update-data`, {
//   //   method: 'PUT',
//   //   headers: { 'Content-Type': 'application/json' },
//   //   body: JSON.stringify(payload),
//   // })
//   //   .then((response) => response.json())
//   //   .then((data) => console.log("Data:", data))
//   //   .catch((error) => console.log(error));
// };

/*
Calculate total for the rolling 12 months
*/
export const YearEndTotal = (arr) => arr.reduce((acc, curr) => acc + (isNaN(curr) ? 0 : curr), 0);

/*
Get all the unique values to show in the selection component
*/
export const GetUniqueValues = (array, key) => {
  return [...new Set(array.map((item) => item[key]))]
};
  