import { Table, Thead, Tr, Th, Tbody, Td } from "@chakra-ui/react";

export default function RegionalLeaderTable({ name, region }) {
  return (
      <Table variant="striped" colorScheme="teal" size="md">
          <Thead bg="teal.500">
              <Tr>
                  <Th color="white">Regional Leader Name</Th>
                  <Th color="white">Region</Th>
              </Tr>
          </Thead>
          <Tbody>
              <Tr>
                  <Td>{name}</Td>
                  <Td>{region}</Td>
              </Tr>
          </Tbody>
      </Table>
  )
}