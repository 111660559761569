import React, { useCallback, useEffect, useState } from 'react';
import { Box, HStack, Table, Tbody, Th, Thead, Tr, Td, Spacer, Text,Button } from '@chakra-ui/react';
import SaveButton from '../../Save';
import useFetch from '../../../hooks/useFetchWithMsal';
import { ApplyCellBg } from '../../../Constants';
import { FormatDateToMonthYear } from './RegionalCanvasHelper';
import EditableRegionalCell from './EditableRegionalCell';
import { pivotParseData, updateTotals } from './RegionalCanvasHelper';
import { rowMapping } from './RegionalCanvasHelper';
import { IoMdPrint } from 'react-icons/io';


export default function RegionalTable({ userProfile, error,saveEnabled , data,setDataSaved ,account_id}) {
    const [, updateData] = useFetch();
    // const [json_data,setJson_data]=useState(data)
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [changesTracker, setChangesTracker] = useState({});
    const [pivotedData, setPivotedData] = useState({})
    const [totals,setTotals]=useState({})
    /**
     * Show Custom Spinner while data is being fetched.
     */
    //   if (loading) return <CustomSpinner text="Loading Editor..." />
    useEffect(() => {
        pivotParseData(data, setPivotedData,setTotals)
    }, [data]);
    console.log(saveEnabled,"saveEnables")
    const uploadData = () => {
        setIsButtonDisabled(true);
        let data = [];
        Object.entries(changesTracker).forEach(([key, newValue]) => {
            const [rowId, columnID, forecast_version_date] = key.split(",");
            data.push({
                date: columnID,
                newValue: newValue === "" ? null : parseFloat(newValue),
                sku_code: rowId,
                regional_leader_id: userProfile,
                forecast_version: forecast_version_date,
                update_date:new Date().toISOString(),
                update_by:account_id
            });
        });
        updateData("/regionalViewUpdateNonForecast", { method: "PUT", body: data }).then((data) => {
            if (data.Error) return;
        }).then(()=>{
        updateTotals(totals,pivotedData,setTotals,setPivotedData,changesTracker)
        setDataSaved(true)
        setChangesTracker({})
    }).catch((error) => {
        console.log(error)
        }

        );
        
    };

    const handleChangesTracker =useCallback((newValue, columnID, rowId, regional_leader_id, forecast_version_date) => {
        setIsButtonDisabled(false);
        setDataSaved(false)
        setChangesTracker((prevChanges) => {
            const updatedChanges = { ...prevChanges };
            const key = `${rowId},${columnID},${forecast_version_date},${regional_leader_id}`;
            updatedChanges[key] = newValue;
            return updatedChanges;
        });
    },[setIsButtonDisabled,setDataSaved,setChangesTracker])


    return (
        <Box minHeight="90vh">
            <HStack spacing={4}>
                <SaveButton isButtonDisabled={(!saveEnabled)||isButtonDisabled} uploadData={uploadData} />
                <Button leftIcon={<IoMdPrint />} style={{marginTop:"16px"}} px={6} py={2} fontSize="md" fontWeight="bold" onClick={() => window.print()} colorScheme='blue'>
                    Print
                </Button>
                <Spacer />
                {/* <Text
        fontSize="sm" // Adjust the font size to your preference
        fontWeight="bold" // Apply bold font weight to the caption
        color="gray.500" // Use a gray color for the caption
        marginBottom="2" // Add some bottom margin for spacing
        >* Data in units</Text> */}
            </HStack>
            <Box height="85vh" overflow="auto" textAlign="center" id='printableArea'>
                <Table textAlign="center">
                    <Thead style={{ position: 'sticky', top: 0, backgroundColor: 'white' ,zIndex:2}} textAlign="center">
                        <Tr key ="Table_Headers">
                            {/* <Th textAlign>Warehouse</Th> */}
                            <Th key ="Key_figure" textAlign="start" style={{minWidth:"200px"}}>Key Figure</Th>
                            {/* {tableHeaders} */}
                            {Object.keys(pivotedData).map((date) => {
                                console.log(date);
                                return <Th key={`${date}-Header`} textAlign>{FormatDateToMonthYear(date)}</Th>
                            })}
                            <Th key="Total" style={{fontWeight:"bold", color:"black"}}>Total</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {/* {pivotedData && pivotedData.forEach((row) => {
                return <td></td>
            }); */}
                        {/* <p>{JSON.stringify(pivotedData[Object.keys(pivotedData)[0]])}</p> */}
                        {rowMapping.map(rowName => {
                                let displayName = rowName.slice(0,rowName.indexOf('-')).trim()
                                console.log("NAME", displayName)
                            // if (rowName == "Prev Fcst - Focus Dealer")
                            if (rowName === "Current Fcst - Total") {
                                return <Tr key={`${rowName}-Main-Row-Total`} style={{ borderTop: '2px solid lightGray'}}>
                                <Td key={`${rowName}-label}`} textAlign="start" style={{...ApplyCellBg('qty_override_forecast'),fontWeight:"bold"}} pl={16}>{displayName}</Td>
                                {Object.keys(pivotedData).map((date) => {
                                    return <EditableRegionalCell
                                        key={`${date}-Final_Forecast`}
                                        keyValue={`${date}-Final_Forecast`}
                                        value={pivotedData[date]['Current Fcst - Total'] && pivotedData[date]['Current Fcst - Total']}
                                        FocusDealerForecast={pivotedData[date]["Current Fcst - Focus Dealer"]}
                                        columnID={date}
                                        onUpdate={handleChangesTracker}
                                        dealer_type='All others'
                                        regional_leader_id={userProfile}
                                        forecast_version_date={pivotedData[date]['Forecast Version']}
                                        current_revenue_forecast={pivotedData[date]['Current Adjusted Revenue']}
                                        saveEnabled={saveEnabled}
                                        pivotedData={pivotedData}
                                        setPivotedData={setPivotedData}
                                    /> 
                                })
                                
                                }
                                <Td key={`${rowName}-label-Totals`} textAlign="start" style={{...ApplyCellBg('qty_override_forecast')}}>{totals[rowName]&&totals[rowName]!==0?parseFloat(totals[rowName]).toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                        maximumFractionDigits: 0,
                                    }):""}</Td> 
                            </Tr>
                            } else {
                                let row = <Tr key={`${rowName}-Main-Row`} style={{ borderTop: '2px solid lightGray' }}>
                                {displayName==="Current Fcst"|displayName==="Calc Current Fcst"?
                                <>
                                <Td textAlign="start" pl={16}  key={`${rowName}-label`} style={{...ApplyCellBg(displayName),fontWeight:"bold"}} >{displayName}</Td>
                                {Object.keys(pivotedData).map((date) => {
                                    return <Td key={`${date}-${rowName}`} style={{...ApplyCellBg(displayName),fontWeight:"bold"}} textAlign>{pivotedData[date][rowName] && Number(pivotedData[date][rowName]).toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                        maximumFractionDigits: 0,
                                    })}</Td>
                                    
                                })}
                                <Td style={{...ApplyCellBg(displayName),fontWeight:"bold"}} key ={`${rowName}-label-Totals`} >{totals[rowName]&&totals[rowName]!==0?Number(totals[rowName]).toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                        maximumFractionDigits: 0,
                                    }):""}</Td>
                                
                                </>
                                :
                                <>
                                <Td textAlign="start" pl={16}  key={`${rowName}-label`} >{displayName}</Td>
                                {Object.keys(pivotedData).map((date) => {
                                    return <Td key={`${date}-${rowName}`} textAlign>{pivotedData[date][rowName] && Number(pivotedData[date][rowName]).toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                        maximumFractionDigits: 0,
                                    })}</Td>
                                })}
                                <Td style={{fontWeight:"bold"}} key ={`${rowName}-label-Totals`} >{totals[rowName]&&totals[rowName]!==0?Number(totals[rowName]).toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                        maximumFractionDigits: 0,
                                    }):""}</Td>
                                    </>
                                    }
                            </Tr>
                            let headers = ["Focus Dealer", "Non Focus Dealer", "Total"]
                            for (let index in headers) {
                                if (rowName === "YAG History - " + headers[index]) {
                                    row = <><Tr key={`${rowName}-Main-Row-${headers[index]}`} style={{ borderTop: '2px solid lightGray' }}><Td style={{fontWeight:"bold"}}>{headers[index]}</Td></Tr>{row}</>
                                }
                            }

                            return row;
                            }
                        })}

                        {/* {Object.keys(pivotedData).map((date) => {
                            const currentAdjustedRevenueForecast = pivotedData[date]["Focus Dealer Forecast"] + pivotedData[date]["Non-focus dealer forecast stat"]
                            const gap = currentAdjustedRevenueForecast - pivotedData[date]["StatRevenueBaseline"]
                            return <Tr>
                                <Td></Td>
                                <Td>{pivotedData[date]["StatRevenueBaseline"]}</Td>
                                <Td>{pivotedData[date]["Last Cycle Focus Dealers Forecast"]}</Td>
                                <Td>{pivotedData[date]["Focus Dealer Forecast"]}</Td>
                                <Td>{pivotedData[date]["Last Cycle Non-Focus Dealers Forecast"]}</Td>
                                <Td>{pivotedData[date]["Non-focus dealer forecast stat"]}</Td>
                                <Td>{pivotedData[date]["Last Cycle Regional Revenue Forecast"]}</Td>
                                <Td>{currentAdjustedRevenueForecast}</Td>
                                <Td>{gap}</Td>
                            </Tr>
                        })} */}
                        {error &&
                            <Tr>
                                <Td colSpan={16} textAlign>
                                    <Text> Error Occured: {error.message} </Text>
                                </Td>
                            </Tr>}
                    </Tbody>
                </Table>
            </Box>
        </Box>

    );
};
