import { useLocation } from 'react-router-dom';
import { Flex, Heading, Spacer, Link, Img,Select } from '@chakra-ui/react';
import UserMenu from './../UserMenu';
import { PRODUCT_NAME } from '../../../Constants';
import { useNavigate } from 'react-router-dom';
import logo from "./../../../logo/logo.png"
import { IoMdArrowDropdown } from 'react-icons/io';

export default function Header({dataSaved,setActiveRegionalID,activeRegionalID,regionalIDList}) {
    console.log(regionalIDList)

    const handleSelectedOptionChange=(event)=>{
        if(!dataSaved && !window.confirm("There are unsaved changes.All the changes would be lost if you navigate by clicking 'OK' . Click 'Cancel' to go back.")){
          return
        }else{
      const temp = regionalIDList.filter(value =>
        value["ID"]===parseInt(event.target.value)
        )
      setActiveRegionalID(temp[0])
      sessionStorage.setItem("aci",JSON.stringify(temp[0]))
      }  
    }
    const NavLink = ({ to, children,dataSave}) =>  {
        const { pathname } = useLocation();

        const isActive = pathname === to;
        const navigate = useNavigate();
        const handleClick = () => {
          if(!dataSave && !window.confirm("There are unsaved changes.All the changes would be lost if you navigate by clicking 'OK' . Click 'Cancel' to go back.")){
              return
        }else{
            navigate(to)
          }
        }

      
        return (
          <Link
            onClick={handleClick}
            px={4}
            fontSize='md'
            fontWeight={isActive ? 'bold' : 'normal'}
            textDecorationLine={isActive?'underline':''}
            _hover={{ textDecoration: 'none',textDecorationLine:"underline" }}
          >
            {children}
          </Link>
        );
    }
    return(
        <Flex
        as="nav"
        bg="teal.500"
        color="white"
        py={2}
        px={4}
        alignItems="center"
        > 
            
            <Img src={logo} backgroundColor="transparent" style={{height:"5vh"}} className="App-logo"/>
            <Heading size="md">&nbsp;&nbsp;{ PRODUCT_NAME }</Heading>
            <Spacer />
            {regionalIDList.length>0 && activeRegionalID && <Select width={'250px'} icon={<IoMdArrowDropdown />} iconColor='black' bg={"whiteAlpha.900"} style={{color:"black",border:"white"}} onChange={handleSelectedOptionChange} >
              <option selected hidden disabled value="">{activeRegionalID["region"]+"-"+activeRegionalID["regional_leader_name"]}</option>
              {regionalIDList && regionalIDList.map((value, index) => (
                    <option key={value["ID"]+value["region"]} value={value["ID"]} style={{color:"black"}}>{value["region"]+"-"+value["regional_leader_name"]}</option>
                ))}
            </Select>}
            <NavLink to={`/regionalDashboard`} dataSave={dataSaved} >Forecast Editor</NavLink>
            <NavLink to={`/regionalDashboardReport`} dataSave={dataSaved} >Forecast Report</NavLink>
            <NavLink to={`/regionalView`} dataSave={dataSaved} >Regional View</NavLink>
            {/* User profile */}
            <UserMenu dataSave={dataSaved}/>
        </Flex>
    );
}