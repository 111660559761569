import { useAccount, useMsal } from "@azure/msal-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { API_URL, protectedResources } from "../Constants";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useToast } from '@chakra-ui/react'

const useFetch = (dataType = {}) => {
    const [data, setData] = useState(dataType);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const toast = useToast()
    const toastIdRef = useRef()

    useEffect(() => {
        if (error) {
            const toastSettings = {
                description: error,
                status: 'error',
                isClosable: true,
                onClose: () => {
                    window.location.reload()
                    toastIdRef.current = null
                }
            }
            if (toastIdRef.current) {
                toast.update(toastIdRef.current, toastSettings)
            } else {
                toast(toastSettings)
            }
            }
    }, [error,toast])

    const fetchData = async (url, {method, body = null}) => {
        if(!url) return
        setLoading(true)
        if (method === "PUT") {
            toastIdRef.current = toast({
                description: "loading",
                status: "loading",
                duration:null
            })
        }
        try {
            const tokenResponse = await instance.acquireTokenSilent({...protectedResources, account: account})
            const json = await callApi(url, {method, body}, tokenResponse.accessToken);
            toast.update(toastIdRef.current, {description: "Data uploaded succesfully", status: "success", duration: 3000})
            return json
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                try {
                    const tokenResponse = await instance.acquireTokenPopup({...protectedResources, account: account});
                    return callApi(url, {method, body}, tokenResponse.accessToken);
                } catch(error) {
                    setLoading(false)
                    setError(error.message);
                    instance.logout();
                }
            }
            console.log("ERROR" + error)
            //This catches the error if either of the promises fails or the manual error is thrown
            setLoading(false)
            setError(error.message)
        }
    }

    const callApi = async (url, {method, body = null}, accessToken) => {
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;            
        headers.append("Authorization", bearer);

        if (body) headers.append('Content-Type', 'application/json');

        let options = {
            method: method,
            headers: headers,
            body: body ? JSON.stringify(body) : null,
        };

        const response = await fetch(API_URL + url, options);
        
        if (!response.ok) {
            const json = await response.json()
            setError(json.Error)
            setLoading(false)
            throw new Error(`HTTP error status: ${response.status}, Message: ${json.Error}`);
        }

        const json = await response.json()
        setData(json)
        setLoading(false)
        setError(null)
        return json
    }

    return [{ data, loading, error }, useCallback(fetchData, [account, instance,toast])];
}

export default useFetch;