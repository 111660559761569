
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
// import { msalConfig } from './authConfig';
import { MSALCONFIG } from "./Constants";
import CacheBuster from './CacheBuster';

const msalInstance = new PublicClientApplication(MSALCONFIG);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
                refreshCacheAndReload();
            }

            return (
                <MsalProvider instance={msalInstance}>
                    <App />
                </MsalProvider>
            );
        }}
    </CacheBuster>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
