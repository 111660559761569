// export const rowMapping = ["Stat Fcst - Total","Stat Fcst - Focus Dealer" ,"Stat Fcst - Non Focus Dealer", "Prev Fcst - Total",
//     "Prev Fcst - Focus Dealer" ,"Prev Fcst - Non Focus Dealer","Adjusted Regional Revenue Forecast","Current Fcst - Focus Dealer","Calc Current Fcst - Non Focus Dealer" ,"Focus Dealer Adjusted vs Last Cycle","Current Fcst - Total"];

export const rowMapping = ["YAG History - Focus Dealer","Prev Fcst - Focus Dealer", "Stat Fcst - Focus Dealer", "Current Fcst - Focus Dealer","YAG History - Non Focus Dealer" ,"Prev Fcst - Non Focus Dealer", "Stat Fcst - Non Focus Dealer", "Calc Current Fcst - Non Focus Dealer","YAG History - Total" ,"Prev Fcst - Total", "Stat Fcst - Total", "Current Fcst - Total"]

// export const indentedRows = ["Stat Fcst - Focus Dealer" ,"Stat Fcst - Non Focus Dealer","Prev Fcst - Focus Dealer" ,"Prev Fcst - Non Focus Dealer","Current Fcst - Focus Dealer","Calc Current Fcst - Non Focus Dealer"] 
export const indentedRows = rowMapping;


export function  pivotParseData (data, setPivotedData,setTotals) {
    const pivotedData1 = {}
    const totals = {}
    data.forEach((forecast) => {
        let additional = {};
        switch (forecast.dealer_type.toLowerCase()) {
            case "all others":
                additional = {
                    "YAG History - Non Focus Dealer": forecast["Year Ago History"],
                    "Prev Fcst - Non Focus Dealer": forecast["Revenue Last Cycle Forecast -Individual"],
                    "Stat Fcst - Non Focus Dealer": forecast["Revenue Stat Baseline - Individual"],
                    "Calc Current Fcst - Non Focus Dealer": forecast["Dealer Forecast - Individual"],
                    "Prev Fcst - Non Focus Dealer without Stat": forecast["Last Cycle Forecast Without Stat - Individual"]
                }
                break;
            case "focus":
                additional = {
                    "YAG History - Focus Dealer": forecast["Year Ago History"],
                    "Prev Fcst - Focus Dealer": forecast["Revenue Last Cycle Forecast -Individual"],
                    "Current Fcst - Focus Dealer": forecast["Dealer Forecast - Individual"],
                    "Stat Fcst - Focus Dealer": forecast["Revenue Stat Baseline - Individual"],
                    "Prev Fcst - Focus Dealer without Stat": forecast["Last Cycle Forecast Without Stat - Individual"]

                }
                break;
        }
        pivotedData1[forecast.Date] = {
            ...pivotedData1[forecast.Date], ...additional, "Stat Fcst - Total": forecast["Total Stat Revenue Baseline"], "Prev Fcst - Total": forecast["Last Cycle Total Revenue Forecast"],
            "Forecast Version": forecast['forecast_version']
        }
        if ("Current Fcst - Focus Dealer" in pivotedData1[forecast.Date] || "Stat Fcst - Non Focus Dealer" in pivotedData1[forecast.Date]) {
            if("Current Fcst - Focus Dealer" in pivotedData1[forecast.Date] && "Calc Current Fcst - Non Focus Dealer" in pivotedData1[forecast.Date]){
                pivotedData1[forecast.Date]["Current Adjusted Revenue"] = pivotedData1[forecast.Date]["Current Fcst - Focus Dealer"] + pivotedData1[forecast.Date]["Stat Fcst - Non Focus Dealer"]
                pivotedData1[forecast.Date]["Focus Dealer Adjusted vs Current Stat"] = pivotedData1[forecast.Date]["Current Fcst - Focus Dealer"] - pivotedData1[forecast.Date]["Stat Fcst - Focus Dealer"]
            }else  if("Current Fcst - Focus Dealer" in pivotedData1[forecast.Date]){
                pivotedData1[forecast.Date]["Current Adjusted Revenue"] = pivotedData1[forecast.Date]["Current Fcst - Focus Dealer"]
                pivotedData1[forecast.Date]["Focus Dealer Adjusted vs Current Stat"] = pivotedData1[forecast.Date]["Current Fcst - Focus Dealer"] - pivotedData1[forecast.Date]["Stat Fcst - Focus Dealer"]
            }else if("Stat Fcst - Non Focus Dealer" in pivotedData1[forecast.Date]){
                pivotedData1[forecast.Date]["Current Adjusted Revenue"] = pivotedData1[forecast.Date]["Stat Fcst - Non Focus Dealer"]
            }
        }  
        if ("Current Fcst - Focus Dealer" in pivotedData1[forecast.Date] || "Calc Current Fcst - Non Focus Dealer" in pivotedData1[forecast.Date]) {
            if("Current Fcst - Focus Dealer" in pivotedData1[forecast.Date] && "Calc Current Fcst - Non Focus Dealer" in pivotedData1[forecast.Date]){
             pivotedData1[forecast.Date]["Adjusted Regional Revenue Forecast"] = pivotedData1[forecast.Date]["Calc Current Fcst - Non Focus Dealer"] + pivotedData1[forecast.Date]["Current Fcst - Focus Dealer"]
             pivotedData1[forecast.Date]["Current Fcst - Total"] = pivotedData1[forecast.Date]["Calc Current Fcst - Non Focus Dealer"] + pivotedData1[forecast.Date]["Current Fcst - Focus Dealer"]
            }
            else if("Current Fcst - Focus Dealer" in pivotedData1[forecast.Date]){
                pivotedData1[forecast.Date]["Adjusted Regional Revenue Forecast"]=pivotedData1[forecast.Date]["Current Fcst - Focus Dealer"]
                pivotedData1[forecast.Date]["Current Fcst - Total"]=pivotedData1[forecast.Date]["Current Fcst - Focus Dealer"]

            }
            else if("Calc Current Fcst - Non Focus Dealer" in pivotedData1[forecast.Date]){
                pivotedData1[forecast.Date]["Adjusted Regional Revenue Forecast"]=pivotedData1[forecast.Date]["Calc Current Fcst - Non Focus Dealer"]
                pivotedData1[forecast.Date]["Current Fcst - Total"]=pivotedData1[forecast.Date]["Calc Current Fcst - Non Focus Dealer"]

            }

        }
        if ("Prev Fcst - Focus Dealer" in pivotedData1[forecast.Date] && "Current Fcst - Focus Dealer" in pivotedData1[forecast.Date]) {
            pivotedData1[forecast.Date]["Focus Dealer Adjusted vs Last Cycle"] = pivotedData1[forecast.Date]["Current Fcst - Focus Dealer"] - pivotedData1[forecast.Date]["Prev Fcst - Focus Dealer"]
       }
       if ("YAG History - Focus Dealer" in pivotedData1[forecast.Date] || "YAG History - Non Focus Dealer" in pivotedData1[forecast.Date]) {
        if ("YAG History - Focus Dealer" in pivotedData1[forecast.Date] && "YAG History - Non Focus Dealer" in pivotedData1[forecast.Date]){
            pivotedData1[forecast.Date]["YAG History - Total"] = pivotedData1[forecast.Date]["YAG History - Focus Dealer"] + pivotedData1[forecast.Date]["YAG History - Non Focus Dealer"]
        }else if ("YAG History - Focus Dealer" in pivotedData1[forecast.Date]){
            pivotedData1[forecast.Date]["YAG History - Total"] = pivotedData1[forecast.Date]["YAG History - Focus Dealer"]
        }
        else if( "YAG History - Non Focus Dealer" in pivotedData1[forecast.Date]){
            pivotedData1[forecast.Date]["YAG History - Total"] = pivotedData1[forecast.Date]["YAG History - Non Focus Dealer"]
        }
        }
        }
    )
    Object.keys(pivotedData1).forEach((date)=>{
        rowMapping.forEach((key)=>{
            totals[key]=(totals[key]||0)+pivotedData1[date][key]
        })
    })
    setPivotedData(pivotedData1)
    setTotals(totals)
}
export const FormatDateToMonthYear = (dateStr) => {
    const date = new Date(dateStr);
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    return `${month}-${year}`;
  };

export function updateTotals(totals,pivotedData,setTotals,setPivotedData,changesTracker){
    let totalsTemp= totals
    let pivotedDatatemp=pivotedData
    Object.entries(changesTracker).forEach(([key, value])=>{
        const date = key.split(",")[1]
        if(value!==null){
        let calcRegionalRevenueForecast =pivotedData[date]["Current Fcst - Focus Dealer"]? parseFloat(value)+pivotedData[date]["Current Fcst - Focus Dealer"]:parseFloat(value)
        totalsTemp["Current Fcst - Total"]=totalsTemp["Current Fcst - Total"]-pivotedData[date]["Current Fcst - Total"]+calcRegionalRevenueForecast
        totalsTemp["Adjusted Regional Revenue Forecast"]=totalsTemp["Adjusted Regional Revenue Forecast"]-pivotedData[date]["Adjusted Regional Revenue Forecast"]+calcRegionalRevenueForecast
        totalsTemp["Calc Current Fcst - Non Focus Dealer"]=totalsTemp["Calc Current Fcst - Non Focus Dealer"]-pivotedData[date]["Calc Current Fcst - Non Focus Dealer"]+parseFloat(value)
        pivotedDatatemp[date]["Calc Current Fcst - Non Focus Dealer"]= parseFloat(value)
        pivotedDatatemp[date]["Current Fcst - Total"]=calcRegionalRevenueForecast
        pivotedDatatemp[date]["Adjusted Regional Revenue Forecast"]=calcRegionalRevenueForecast
        }
        else{
        totalsTemp["Current Fcst - Total"]=totalsTemp["Current Fcst - Total"]-pivotedData[date]["Current Fcst - Total"]+pivotedData[date]["Current Adjusted Revenue"]
        totalsTemp["Adjusted Regional Revenue Forecast"]=totalsTemp["Adjusted Regional Revenue Forecast"]-pivotedData[date]["Adjusted Regional Revenue Forecast"]+pivotedData[date]["Current Adjusted Revenue"]
        totalsTemp["Calc Current Fcst - Non Focus Dealer"]=totalsTemp["Calc Current Fcst - Non Focus Dealer"]-pivotedData[date]["Calc Current Fcst - Non Focus Dealer"]+pivotedData[date]["Stat Fcst - Non Focus Dealer"]
        pivotedDatatemp[date]["Current Fcst - Total"]=pivotedData[date]["Current Adjusted Revenue"]
        pivotedDatatemp[date]["Calc Current Fcst - Non Focus Dealer"]=pivotedData[date]["Stat Fcst - Non Focus Dealer"]
        }
    })
    setTotals(totalsTemp)
    setPivotedData(pivotedDatatemp)
    
}