import { Box } from '@chakra-ui/react';
import DashboardContent from './Content';
import { useEffect, useState } from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import Header from './Header';
import { useMsal,useAccount } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import useFetch from '../../../hooks/useFetchWithMsal';
import { Container } from '@chakra-ui/react';
import { CustomSpinner } from '../../Spinner';

function RegionalDashboard() {
  const [isDataSavedSku,setIsDataSavedSku]=useState(true)
  const [isDataSavedOther,setIsDataSavedOther]=useState(true)
  const {accounts } = useMsal();
  const navigate=useNavigate()
  const account = useAccount(accounts[0] || {});
  const [activeRegion,setActiveRegion]=useState(null)
  const [{ loading, error}, fetchData] = useFetch();
  const [regionalIDList,setRegionalIDList]=useState([])
 
  useEffect(()=>{
    if(!account){
      navigate("/")
  }
  else if(account.idTokenClaims.roles.includes("global_access")){
    if(sessionStorage.getItem("regionList")){  
       var regionalIDList=JSON.parse(sessionStorage.getItem("regionList"))
       if(!regionalIDList["save_enabled"]){
        navigate("/regionalDashboardReport")
      }
       setRegionalIDList(regionalIDList["result"])
       let aci = JSON.parse(sessionStorage.getItem("aci"))
       if(aci){
         setActiveRegion(aci)
       }
       else{
         setActiveRegion(regionalIDList["result"][0])
         sessionStorage.setItem("aci",JSON.stringify(regionalIDList["result"][0]))
       }
      setIsDataSavedSku(true)
      setIsDataSavedOther(true)
     }
     else{
      fetchData(`/regional_leader_id`, { method: "GET" }).then((data) => {
        setRegionalIDList(data["result"])
        sessionStorage.setItem('regionList',JSON.stringify(data))
        setActiveRegion(data["result"][0])
        if(!data["save_enabled"]){
          navigate("/regionalDashboardReport")
        }
        let aci = JSON.parse(sessionStorage.getItem("aci"))
        if(aci){
          setActiveRegion(aci)
        }
        else{
          setActiveRegion(data["result"][0])
          sessionStorage.setItem("aci",JSON.stringify(data["result"][0]))
        }
      })
        setIsDataSavedSku(true)
        setIsDataSavedOther(true)
   }
  }
   else{
     let aci = JSON.parse(sessionStorage.getItem("aci"))
     if(aci){
      if(!aci[0]["save_enabled"]){
        navigate("/regionalDashboardReport")
      }
       setActiveRegion(aci[0])
     }
     else{
      fetchData("/regional_leader", {method: "POST", body: {userID: account.idTokenClaims.EmployeeID}}).then((data)=>{
        setActiveRegion(data[0])
        if(!data[0]["save_enabled"]){
          navigate("/regionalDashboardReport")
        }
        sessionStorage.setItem("aci",JSON.stringify(data))
    });
      setIsDataSavedSku(true)
      setIsDataSavedOther(true)
   }
  }
  },[account,navigate,setIsDataSavedSku,setIsDataSavedOther,fetchData])

  if(error) return (<p>Follwing Error has occurred {error}</p>)
  if (loading || !activeRegion) return (
      <Container minHeight="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center">
      < Box
          className="Loading-Data"
          maxW="md"
          mx="auto"
          p={6}
          borderRadius="xl"
          boxShadow="md"
          backgroundColor={"white"}
      >
          <CustomSpinner text="Loading Your Data.. Please Wait" />
      </Box >
      </Container>
  )

  return (
      <AuthenticatedTemplate>
        <Box h="100vh">
          <Header dataSaved={isDataSavedSku&&isDataSavedOther} setActiveRegionalID={setActiveRegion} activeRegionalID={activeRegion} regionalIDList={regionalIDList}/>
          <DashboardContent setDataSavedSku={setIsDataSavedSku} setDataSavedOther={setIsDataSavedOther} activeRegion={activeRegion} account_id={account.idTokenClaims.EmployeeID}/>
        </Box>
      </AuthenticatedTemplate>
  );
}

export default RegionalDashboard;