export let API_URL;
if (process.env.REACT_APP_NODE_ENV === 'production') {
  const HOSTNAME = "planvida-api.azurewebsites.net";
  // const HOSTNAME = "localhost:8000";
  API_URL = `https://${HOSTNAME}/api`;
}
else {
  const HOSTNAME = "localhost:7071";
  API_URL = `http://${HOSTNAME}/api`;
}

export const VERSION = `3.0 beta`

export let REDIRECT_URI;

if (process.env.REACT_APP_NODE_ENV === 'production') {
  // Use the production API URL with https
  REDIRECT_URI = `https://${window.location.host}/`;
} else if(process.env.REACT_APP_NODE_ENV === 'testing'){
  // Use the localhost API URL with http
  REDIRECT_URI = `https://${window.location.host}/`;
}else
{
  // Use the localhost API URL with http
  REDIRECT_URI = `http://${window.location.host}/`;
}

export const PRODUCT_NAME = "Dealer Forecast";

export const MSALCONFIG = {
    auth: {
      clientId: process.env.REACT_APP_CLIENT_ID,
      authority: "https://login.microsoftonline.com/" + process.env.REACT_APP_TENANT_ID +"/",
      redirectUri: `${REDIRECT_URI}`,
    },
};



export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
}



export const loginRequest = {
  forceRefresh: false
};

export const ApplyCellBg = (type) => (
  type === 'qty_override_forecast' || 
  type === 'distributor_current_forecast' ||
  type === 'consultant_current_forecast' ||
  type === 'revenue_current_forecast' ||
  type === 'show_current_forecast'||
  type=== 'Current Fcst'||type === "Calc Current Fcst"
  ? { backgroundColor: '#f5deb3', borderTop: '2px solid lightGray',fontWeight:"bold" } : {
    borderTop: '2px solid lightGray'
  });

export const FormatDateToMonthYear = (dateStr) => {
  const date = new Date(dateStr.replace(/-/g, '\/'));
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear();
  return `${month}-${year}`;
};

export const protectedResources = {
  scopes: ["https://planvida-api.onmicrosoft.com/api.readWrite"]
}
